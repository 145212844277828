import React from 'react';
import ResponsiveSlider from '../responsive-slider/responsive-slider';
import styles from './staffing-solutions-slider.module.scss';

export default () => {
  const slides = [
    {
      title: 'Single point of contact',
      description:
        'Your Trustaff account manager is your guide to a great staffing experience. Having a single, personal point of contact with 24/7 availability means one call or email is all it takes to snap our entire team into action!',
      iconClass: styles.slideIconTeam,
    },
    {
      title: 'Review candidates quickly',
      description:
        'The clock is ticking. You need qualified professionals and you need them now. Our proprietary database allows us to present proven candidates for your review as quickly as possible.',
      iconClass: styles.slideIconSpeed,
    },
    {
      title: 'High-quality patient care',
      description:
        'Patient care is more important than ever before. It is critical that anyone working at your facility seamlessly becomes part of your team. We screen and verify the skills and credentials of every professional we offer for your consideration.',
      iconClass: styles.slideIconCaring,
    },
  ];

  return <ResponsiveSlider slides={slides} />;
};
