import React from 'react';
import HeroImage from './hero-image';

import styles from './hero-image-mini.module.scss';

const HeroImageMini = props => {
  return (
    <HeroImage
      imgWrapperClass={styles.heroMiniImageWrapper}
      heroOverlayClass={
        props.hasOverlay
          ? styles.heroMiniWithOverlay
          : styles.heroMiniTransparentOverlay
      }
      {...props}
    />
  );
};

export default HeroImageMini;

HeroImage.defaultProps = {
  hasOverlay: false,
};
