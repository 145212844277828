import React from 'react';
import { Link } from 'gatsby';
import styles from './staffing-solutions-about-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Discover the better way to manage your workforce
          </h3>
          <div className="content">
            <p className="content">
              Our job is to help you contain costs, streamline hiring, and
              reduce your staffing headaches. With the Trustaff team in your
              back pocket, you have more control over exactly what you need—and
              when.
            </p>
            <ul className={`is-size-7`}>
              <li>Travel, contract, per diem, and direct-hire staffing</li>
              <li>Healthcare professionals of all types and skill levels</li>
              <li>
                Candidates carefully screened &amp; vetted to your
                organization's standards
              </li>
              <li>
                Fast turnaround—you need manpower, and you need it quickly!
              </li>
              <li>
                Our priority is dependability, compliance, and great patient
                care
              </li>
            </ul>
            <p className="content">
              We take great pride in the personalized service we provide our
              clients, as well as the integrity, skill, and dependability of
              each healthcare professional we represent. We are proud to be
              certified by{' '}
              <a href="https://www.jointcommission.org/en/">
                The Joint Commission
              </a>{' '}
              for <b>Excellence in Healthcare Staffing</b>.
            </p>
          </div>
          <Link to="/staffing-solutions/request/" className="button is-primary">
            Submit a request
          </Link>
        </div>

        <div className={`column is-5 is-offset-1`}>
          <div className={`${styles.embedContainer}`}>
            <iframe
              title="vimeoVideo"
              src="https://player.vimeo.com/video/263172750"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
