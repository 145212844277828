import React from 'react';
import styles from './staffing-solutions-bullets-section.module.scss';

export default () => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Not just great hires—great people
          </h3>
          <div className="content">
            <p className={`content`}>
              Trustaff is proud to be well-recognized for our success and
              continued growth. We have a strong reputation within the industry
              itself, as demonstrated by our high standing with so many of our
              partners.
            </p>
            <p className={`content ${styles.subheader}`}>
              Just a few of our recent partner accolades include:
            </p>
            <ul className={`is-size-7`}>
              <li>
                Awarded{' '}
                <a
                  href="https://www.ayahealthcare.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Aya Healthcare's
                </a>{' '}
                <b>Spotlight Award</b> 2021
              </li>
              <li>
                Awarded{' '}
                <a
                  href="https://www.amnhealthcare.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AMN's
                </a>{' '}
                <b>Supplier Partner Award</b> Essential Partner - COVID Response
                2020
              </li>
              <li>
                Awarded{' '}
                <a
                  href="https://healthtrustpg.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HealthTrust's
                </a>{' '}
                <b>PMO Choice Award</b> for 2019 &amp; 2020
              </li>
              <li>
                Recognized by{' '}
                <a
                  href="https://www.amnhealthcare.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AMN Healthcare
                </a>{' '}
                with their 2018 &amp; 2019 <b>Spirit of Alliance</b> partnership
                awards
              </li>
              <li>
                Named by{' '}
                <a
                  href="https://www.rightsourcingusa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RightSourcing
                </a>{' '}
                as their 2017 &amp; 2018 <b>Partner of the Year</b>
              </li>
              <li>
                Recognized by{' '}
                <a
                  href="https://www.focusonesolutions.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FocusOne Solutions
                </a>{' '}
                with the <b>2018 Partnership Collaboration Award</b>
              </li>
              <li>
                <b>Most Ethical, Trustworthy, and Reliable Company</b> as
                awarded by the{' '}
                <a
                  href="https://www.ohiohospitals.org/Home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ohio Hospital Association
                </a>{' '}
                (2016)
              </li>
              <li>
                <b>Most Valued Partner for Travel Nursing</b> as awarded by AMN
                (2015)
              </li>
              <li>
                <b>MSP Supplier Partner of the Year</b> as awarded by{' '}
                <a
                  href="https://www.strategicstaff.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Strategic Staffing Solutions
                </a>{' '}
                (2015)
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <div className={`${styles.embedContainer}`}>
            <iframe
              title="video300755510"
              src="https://player.vimeo.com/video/300755510"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
