import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './index.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import StaffingSolutionsSlider from '../../components/staffing-solutions/staffing-solutions-slider';
import StaffingSolutionsAboutSection from '../../components/staffing-solutions/staffing-solutions-about-section';
import StaffingSolutionsBulletsSection from '../../components/staffing-solutions/staffing-solutions-bullets-section';
import StaffingSolutionsClosingSection from '../../components/staffing-solutions/staffing-solutions-closing-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getStaffingSolutionsImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getStaffingSolutionsImageEdge('staffing-solutions-og')
    .node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Staffing solutions - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Trustaff is a recognized leader in healthcare staffing that utilizes innovative recruitment strategies to deliver top talent to companies like yours all across the country."
        />
        <meta property="og:title" content="Staffing solutions" />
        <meta
          property="og:description"
          content="Trustaff is a recognized leader in healthcare staffing that utilizes innovative recruitment strategies to deliver top talent to companies like yours all across the country."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/staffing-solutions/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getStaffingSolutionsImageEdge('staffing-solutions').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-8 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Your one-stop staffing partner
                </h1>
                <p className={`content`}>
                  Selecting the right staffing partner is one of the most
                  immediate ways you can impact both your quality of patient
                  care and your bottom line. When you partner with Trustaff, you
                  get the advantage of over 20 years of healthcare staffing
                  experience, including our robust internal resources and the
                  high-quality standards that define our trusted reputation.
                </p>
                <p className={`content`}>
                  Whether you need to fill an open spot in the Emergency Room
                  due to temporary absence or you need to cover an entire floor
                  for a special project, our team has extensive experience
                  filling all types of positions on all types of timelines.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <StaffingSolutionsAboutSection
            fluid={
              getStaffingSolutionsImageEdge('staffing-solutions-sidebar-1').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionBackgroundGrey}`}>
          <StaffingSolutionsSlider />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <StaffingSolutionsBulletsSection />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <StaffingSolutionsClosingSection />
        </div>
        <HeroImageMini
          fluid={
            getStaffingSolutionsImageEdge('cincinnati-skyline').node
              .childImageSharp.fluid
          }
        ></HeroImageMini>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^staffing-solutions/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
