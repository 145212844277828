import React from 'react';
import styles from './staffing-solutions-closing-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Let us put our reputation to work for you
          </h3>
          <div className="content">
            <p className={`content`}>
              With almost twenty years in business, choosing to become a{' '}
              <b>Trustaff partner</b> comes along with an entire team dedicated
              to ensuring your organization has exactly what you need to
              continue to succeed.
            </p>
            <p className="content">
              Curious about how Trustaff can help simplify your hiring
              headaches? Connect with an account manager today.
            </p>
          </div>
          <a href="/contact-us/" className="button is-info">
            Contact us
          </a>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <p className={`content ${styles.testimonialtext}`}>
            “We are pleased and impressed with Trustaff and the quality of
            candidates they provide. It is comforting to know we are working
            with a reliable, high quality agency.
            <br />
            <br />
            My account manager is a true rock star and has been an amazing
            partner!”
          </p>
          <p className="content">— Karyn</p>
        </div>
      </div>
    </div>
  );
};
